/*!
 =========================================================
 * Paper Kit Pro - v2.3.1
 =========================================================
 */

@import 'theme/variables';
@import 'theme/mixins';

// Plugins CSS
@import "theme/plugins/plugin-bootstrap-switch";
@import "theme/plugins/plugin-nouislider";
@import "theme/plugins/plugin-bootstrap-select";
@import "theme/plugins/plugin-tagsinput";
@import "theme/plugins/plugin-fileupload";
@import "theme/plugins/plugin-datetimepicker";

// Core CSS
@import "theme/nucleo-outline";
@import "theme/alerts";
@import "theme/checkbox-radio";
@import "theme/badges";
@import "theme/pagination";
@import "theme/typography";
@import "theme/misc";
@import "theme/misc-extended";
@import "theme/buttons";
@import "theme/social-buttons";
@import "theme/inputs";
@import "theme/progress-bars";
@import "theme/tooltips-and-popovers";

// components
@import "theme/tabs-navs";
@import "theme/navbars";
@import "theme/navbar-extend";
@import "theme/dropdown-extend";
@import "theme/images";
@import "theme/tags";
@import "theme/animated-buttons";
@import "theme/preloaders";
@import "theme/tables";
@import "theme/info-areas";
@import "theme/media";
@import "theme/modal";
@import "theme/carousel";
@import "theme/footers";
@import "theme/headers";
@import "theme/collapse";

// cards
@import "theme/cards";
@import "theme/cards/card-background";
@import "theme/cards/card-blog";
@import "theme/cards/card-contact";
@import "theme/cards/card-image";
@import "theme/cards/card-register";
@import "theme/cards/card-plain";
@import "theme/cards/card-pricing";
@import "theme/cards/card-profile";
@import "theme/cards/card-product";
// @import "theme/cards/card-signup";
@import "theme/cards/card-testimonials";

// For gallery, using PhotoSwipe
@import "theme/plugins/photo-swipe/main.scss";
@import "theme/plugins/photo-swipe/default-skin.scss";
@import "theme/plugins/photo-swipe/gallery.scss";
@import "theme/plugins/photo-swipe/main.scss";

// Isometric cards plugin
@import "theme/plugins/plugin-isometricGrids";

// example pages and sections
@import "theme/examples";
@import "theme/sections";
@import "theme/sections-extend";

@import "theme/responsive";

// Nucleo Icons
@import "theme/nucleo-icons";
