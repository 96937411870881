/* ----------------------------
	Loader
---------------------------- */

#loader{
  position: fixed;
  width: 50px;
  height: 50px;
  top : 15px;
  right: 15px;
  z-index:2000;
}
#loader i {
  font-size: 50px;
  color: $color0;
}

@keyframes loader-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.transition{
  position: fixed;
  width:100%;
  height:100%;
  background: #222222;
  z-index: 1;
  opacity: 0;
  pointer-events: none;
  transition : ease-in 0.4s;
}
.transition.active{
  opacity: 1;
  pointer-events: none;
}
