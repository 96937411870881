
/* ----------------------------
	NotFound
---------------------------- */

.notFound {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  margin: 0;
  background: #eceff1;
}