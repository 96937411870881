
/* ----------------------------
	Post
---------------------------- */

/* category */
.category {
  h2 {
    color: #141414;
    font-family: 'Trebuchet MS', Arial, sans-serif;
    font-weight: 400;
    text-transform: uppercase;
    padding: 0;
    font-size: 19px;
    letter-spacing: 3px;
    margin-top: 25px;
    margin-left: 5px;
  }

  p {
    color: #141414;
    font-family: 'Trebuchet MS', Arial, sans-serif;
    font-weight: 100;
    padding: 0;
    font-size: 16px;
    margin-left: 5px;
  }
}

/* Post */
#post {
  position: relative;
  display: block;
  padding: 10px;
  clear: both;
  flex-shrink:1;
  max-width:100%;
}
.post {
  position: relative;
  display: block;
  //background-color: #fafafa;
  //border: 1px solid  #dbdbdb;
  clear: both;
  h2 {
    color: #000;
    font-family: 'Open Sans Condensed', sans-serif;
    font-size: 2rem;
    font-weight: 100;
    line-height: 32px;
    text-align: left;
    text-decoration: none;
    text-transform: uppercase;
  }
  .dates {
    position: relative;
    font-size: 1rem;
    font-weight: 100;
    color: #777;
    margin-top: 5px;
    padding: 2px;
    letter-spacing: 0;
    text-align: left;
  }
  .post-content {
    position : relative;
    display: block;
    height: auto;
    padding: 2px;
    border: 1px solid transparent;
    font-size: 1.1rem;
    line-height: 1em;
    font-weight: 500;
    color: #141414;
    a {
      color: #006699;
      font-weight: bold;
      .exterieur:after {
        font-size: 16px;
        font-weight: bold;
        content: "\0000a0[\2197]";
      }
    }
  }
  img {
    vertical-align: center;
    margin: 0 auto;
    text-align: center;
  }

  .listing-documents {
    list-style-type: none;
    padding: 5px;
    margin: 20px auto;
    color: #555;
    li {
      img {
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
        border-radius: 5px;
      }
    }
  }
}

#pictures {
  position: relative;
  display: block;
  width: 100%;
  clear: both;
}
#bx-pager {
  padding: 0;
  > a {
    display: inline-block;
    margin: 5px auto;
    line-height: 1px;
    border: 1px solid #dbdbdb;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    overflow: hidden;
    vertical-align: middle;
    height: 150px;
  }
  > a img {
    display: block;
    min-width: 100%;
    min-height: 150px;
  }
}
#picture {
  position: relative;
  display: block;
  width: 100%;
  max-width:100%;
  clear: both;
  margin: 20px auto;
}

/*#pictures {
    position : relative;
    display: block;
    width: 100%;
    clear: both;
    flex-shrink:1;
    max-width:100%;
}
.pictures {
    display:inline-block;
    margin: 5px auto;
    line-height: 1px;
    border: 1px solid #dbdbdb;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    overflow: hidden;
    vertical-align: middle;
    height: 150px;
    overflow: hidden;
}
.pictures img {
    display: block;
    min-width: 100%;
    min-height: 150px;
}

#picture {
    position: relative;
    display: block;
    width: 100%;
    overflow: hidden;
    flex-shrink:1;
    max-width:100%;

}
.picture {
    display:block;
    margin: 0 auto;
    line-height: 1px;
    border: 1px solid #dbdbdb;
    background: #fff;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    overflow: hidden;
    vertical-align: middle;
}
*/
.img-centered {
  vertical-align: middle;
  margin: 0 auto;
  text-align: center;
}

/* Posts */
#posts {
  clear: both;
}
.posts {
  max-height: 250px;
  // background-image: url("../img/icons/opaque.png");
  background-position: top left;
  background-repeat: repeat;
  overflow: hidden;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  &:hover {
    text-decoration: none;
  }
  .picture {
    width: auto;
    height: 250px;
    padding: 0;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    img {
      vertical-align: middle;
      width: 100%;
      height: auto;
      min-height: 250px;
      line-height: 100%;
      position: relative;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .title{
    position: absolute;
    width: 102%;
    height: auto;
    max-height: 120px;
    bottom: 0;
    left: -1%;
     // background: url('../img/icons/opaque.png') top left repeat;
    color: #FAFAFA;
    overflow: hidden;
    h2 {
      color: #fafafa;
      font-family: 'Open Sans Condensed', sans-serif;
      font-size: 1.1rem;
      font-weight: 600;
      line-height: 32px;
      text-decoration: none;
      text-transform: uppercase;
      padding: 5px;
      vertical-align: middle;
      letter-spacing: .2em;
      text-align:center;
    }
    h2:hover {
      text-decoration:none;
    }
  }
  &:hover .titre{
    text-decoration:none;
  }
  &:hover a {
    text-decoration:none;
  }
  a:hover {
    text-decoration:none;
  }
  /*.dates {
     font-family: Tahoma, Verdana, Arial, Helvetica, sans-serif;
     font-size: 13px;
     font-weight: normal;
     color: #777;
     padding: 2px;
     letter-spacing: 0;
     margin: 0;
     text-align: center;
     padding: 0;
 }*/
}

.picture_effect {
  img {
    left: 0;
    position: relative;
    -webkit-transition: all 0.8s ease-in-out;
    -moz-transition: all 0.8s ease-in-out;
    -o-transition: all 0.8s ease-in-out;
    -ms-transition: all 0.8s ease-in-out;
    transition: all 0.8s ease-in-out;
  }
  &:hover img {
    -webkit-transform: scale(1.2) rotate(2deg);
    -moz-transform: scale(1.2) rotate(2deg);
    -o-transform: scale(1.2) rotate(2deg);
    -ms-transform: scale(1.2) rotate(2deg);
    transform: scale(1.2) rotate(2deg);
  }
}

/* slider footer*/
.slider-footer {
  display: block;
  width: 100%;
  height: 275px;
  margin: 20px auto;
  padding: 5px 40px;
  clear: both;
  .bxslider-footer {
    li {
      height: 250px;
      overflow: hidden;
      border: 1px solid #ccc;
    }
    .image {
      height: 200px;
      overflow: hidden;
      img {
        min-height: 200px;
      }
    }
    .caption {
      line-height: 200px;
      position: relative;
      padding: 5px;
      color: #000;
      text-align: center;
      h3 {
        color: #333;
        text-transform: uppercase;
        padding: 0;
        vertical-align: middle;
        font-size: 1rem;
        font-weight: 300;
        margin-top: 0;
        margin-bottom: 0;

        a {
          color: #333;
          text-transform: uppercase;
          padding: 0;
          vertical-align: middle;
          font-size: 1rem;
          font-weight: 300;
          margin-top: 0;
          margin-bottom: 0;
        }
      }
    }
    .bx-wrapper .bx-controls {
      display: none !important;
    }
  }
}


/*:.button-ajax-action {
    position: absolute;
    top: 35px;
    right: 5px;
    text-align: center;
    padding: 0px!important;
    color: #990000!important;
    z-index: 100;
    font-size: 14px;
    font-weight: bold;
    vertical-align: middle;
}
.button-ajax-action1 {
    width: 30px;
    position: absolute;
    top: 35px;
    right: -5px;
    padding: 0px!important;
    z-index: 100;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    //vertical-align: middle;
    a {
        position: relative;
        margin: 0 auto;
        padding: 0;
        color: #fff;
        i {
            position: relative;
            margin: 0 auto;
            color: #fff;
        }
    }
}
.button-ajax-action2 {
    width: 30px;
    position: absolute;
    top: 70px;
    right: -5px;
    padding: 0px!important;
    z-index: 100;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    //vertical-align: middle;
    a {
        position: relative;
        margin: 0 auto;
        padding: 0;
        color: #fff;
        i {
            position: relative;
            margin: 0 auto;
            color: #fff;
        }
    }
}
*/

/* carousel
.page-carousel {
    max-height: 300px;
}
.carousel-inner {
    max-height: 300px;
    overflow: hidden;
    border-radius: 12px !important;
}
.carousel-item {
    max-height: 300px;
    overflow: hidden;
    img {
        min-width: 100%;
    }
}
.carousel-caption {
    .pas_de_photo {
        font-size: 18px;
        font-weight: bold;
        color: $cdanger;
    }
}
*/
