
/* ----------------------------
	HEADER
---------------------------- */

.navbar {
  min-height:70px;
  z-index: 999;
  width:100%;
  background-color: #ffffff !important;
  -webkit-font-smoothing: antialiased;
  font-family: "Raleway", "Helvetica", Arial, sans-serif !important;
  .navbar-brand {
    font-size: 36px!important;
    color: $color0!important;
    font-weight: 300!important;
    font-family: "Raleway", "Helvetica", Arial, sans-serif !important;
    &:hover {
      color: darken($color0,10%) !important;
      text-decoration: none;
    }
  }
}

.navbar-light .navbar-toggler {
  border: none!important;
  &:focus {
    box-shadow: none!important;
  }
}

.navbar .navbar-nav .nav-item {
  letter-spacing: 1px;
}

.navbar .navbar-nav .nav-item .nav-link.btn {
  margin: 15px 5px;
  padding: 6px 13px;
}

.header-space {
  height: 70px;
}
