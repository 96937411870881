
/* ----------------------------
	Typo
---------------------------- */

a {
	color: $linkcolor;
    text-decoration: none;
    -webkit-transition: 300ms;
    -moz-transition: 300ms;
    -o-transition: 300ms;
    transition: 300ms;
    z-index: 2;
}

a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
	text-decoration: none;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}
a:focus {
  text-decoration: none;
}
a:hover {
	color: $linkcolorhover!important;
	text-decoration: none;
}

.ml-auto, .mx-auto {
  margin-left: auto!important;
}
