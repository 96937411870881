/* ----------------------------
	Social
---------------------------- */

.sociallinks {
  position: fixed;
  top: 90px;
  right: 20px;
  z-index: 997;
}

@media (max-width: 767px) {
  .sociallinks {
    display: none;
  }
}

.socialnav {
  ul {
    list-style: none;
    li {
      margin-top: 10px;
    }
  }
}

.btn-just-icon {
  i{
    color: #FFFFFF !important;
    opacity: 1;
  }
  &:hover,
  &:focus,
  &:active,
  &.active {
    i{
      color: #FFFFFF !important;
      opacity: 1;
    }
  }
}
