
/* ----------------------------
	Variables
---------------------------- */

// couleur par defaut
$color0: #66615B;
$color0h: darken($color0,10%);
$color0a: lighten($color0,10%);

// couleur lien
$linkcolor: #66615B;
$linkcolorhover: darken($linkcolor,10%);
$linkcolorlight: lighten($linkcolor,10%);


