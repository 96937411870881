
/* ----------------------------
	Posts
---------------------------- */

.projects-1 {
  clear: both;
}

.img-centered {
  vertical-align: middle;
  margin: 0 auto;
  text-align: center;
}

.posts {
  &:hover {
    text-decoration: none;
  }
  &:hover a {
    text-decoration:none;
  }
  a:hover {
    text-decoration:none;
  }
  /*.dates {
     font-family: Tahoma, Verdana, Arial, Helvetica, sans-serif;
     font-size: 13px;
     font-weight: normal;
     color: #777;
     padding: 2px;
     letter-spacing: 0;
     margin: 0;
     text-align: center;
     padding: 0;
 }*/
}

.card[data-background="image"]:after {
  background-color: rgba(0, 0, 0, 0.70);
}

.card[data-background="image"] .card-body {
  position: absolute;
  z-index: 2;
  min-height: 280px;
  padding-top: 20px;
  padding-bottom: 40px;
  //max-width: 440px;
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.card[data-background="image"] h6 {
  text-align: right;
  letter-spacing: 2px;
}

.card[data-background="image"] .card-title {
  text-align: center;
  font-weight: 500;
}

.card[data-background="image"] .card-description {
  text-align: justify-all;
}

.picture {
  width: 100%;
  height: 280px;
  padding: 0;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  img {
    vertical-align: middle;
    width: 100%;
    height: auto;
    min-height: 250px;
    //line-height: 100%;
    line-height: 1px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
  }
}

/*.picture_effect {
  img {
    left: 0;
    position: relative;
    -webkit-transition: all 0.8s ease-in-out;
    -moz-transition: all 0.8s ease-in-out;
    -o-transition: all 0.8s ease-in-out;
    -ms-transition: all 0.8s ease-in-out;
    transition: all 0.8s ease-in-out;
  }
  &:hover img {
    -webkit-transform: scale(1.2) rotate(2deg);
    -moz-transform: scale(1.2) rotate(2deg);
    -o-transform: scale(1.2) rotate(2deg);
    -ms-transform: scale(1.2) rotate(2deg);
    transform: scale(1.2) rotate(2deg);
  }
}*/
