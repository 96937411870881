/* ----------------------------
	Footer
---------------------------- */

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.copyright {
  color: #cccccc!important;
  margin: 25px 5px!important;
  line-height: 20px;
  text-align: right!important;
  a {
    color: #cccccc!important;
    &:hover {
      color: #cccccc!important;
      text-decoration: underline;
    }
  }
}
