/* ----------------------------
	Gallery
---------------------------- */

.projects-1 {
  clear: both;
}

.img-centered {
  vertical-align: middle;
  margin: 0 auto;
  text-align: center;
}

.medias {
  &:hover {
    text-decoration: none;
  }
  &:hover a {
    text-decoration:none;
  }
  a:hover {
    text-decoration:none;
  }
}

.card-media {
  width: 100%;
  height: 320px;
  padding: 0;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  border-radius: 12px;
  img {
    vertical-align: middle;
    width: 100%;
    height: auto;
    min-height: 280px;
    //line-height: 100%;
    line-height: 1px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
  }
}


